export interface ISectionProps {
  id?: string
  className?: string
  children: React.ReactNode
}

export default function Section(props: ISectionProps) {
  const { id } = props
  const { className } = props
  const { children } = props

  return (
    <section id={id} className={`${className} border-b`}>
      <div className="container mx-auto py-12 px-2 md:py-16">{children}</div>
    </section>
  )
}
