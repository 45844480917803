import Section from '@/components/Section'
import CTA from '../Cta'

export default function Services() {
  return (
    <div id="services">
      <Section>
        <div data-aos="fade-up">
          <h2>
            A creative collective <span>commited to real-time success.</span>
          </h2>
          <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
            <div>
              <h3>Design</h3>
              <p>
                Passionate UX experts and UI Designers providing solutions to complex design problems with creative
                designs.
              </p>
            </div>
            <div>
              <h3>Development</h3>
              <p>
                Commited Developers with extraordinary problem-solving skills and agile development with dead-simple
                code.
              </p>
            </div>
            <div>
              <h3>Consulting</h3>
              <p>Entrepreneurial realtime collaboration with management, stakeholders and founders that get results.</p>
            </div>
          </div>
        </div>
      </Section>
      <CTA />
    </div>
  )
}
