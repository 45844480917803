import { Fragment, useRef } from 'react'
import { Link } from 'react-scroll'
import { Popover, Transition } from '@headlessui/react'

const pages = [
  { id: `home`, title: `Home` },
  { id: `services`, title: `Services` },
  { id: `about`, title: `About` },
  { id: `contact`, title: `Contact` },
]

export default function Header() {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const closeNavbar = () => {
    buttonRef.current?.click()
  }

  return (
    <header
      className={`sticky top-0 z-50  h-[60px] bg-white px-2 opacity-95  shadow-lg backdrop-blur-2xl  transition
        duration-300 ease-in-out  md:py-0`}
    >
      <div className="container mx-auto flex flex-wrap items-center justify-between pt-2 sm:px-2 md:pt-0">
        <Link
          className="self-center whitespace-nowrap text-2xl font-black uppercase tracking-tight"
          href={`#${pages[0].id}`}
          to={pages[0].id}
          smooth="easeOutQuart"
          offset={-60}
          duration={450}
        >
          Dovergence
        </Link>
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                ref={buttonRef}
                className={`${open ? `` : `rotate-90 transform`}
                group inline-flex items-center rounded-md bg-gray-100 p-2
                text-sm text-gray-500 transition  duration-150  ease-in-out
                focus:outline-none md:hidden`}
              >
                <svg width="24" height="24" fill="none" aria-hidden="true">
                  <path
                    d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="popover-panel absolute left-full z-10 mt-4 w-screen max-w-xs -translate-x-full transform p-0 px-0 md:hidden">
                  <div className="relative overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-8 rounded-lg bg-white p-7 lg:grid-cols-2">
                      {pages.map((item, i) => (
                        <Link
                          key={item.title}
                          className="navbar-link font-xl -m-3 rounded-lg p-2 text-center font-bold transition duration-150 ease-in-out"
                          activeClass="active"
                          href={`#${pages[i].id}`}
                          to={pages[i].id}
                          smooth="easeOutQuart"
                          spy
                          offset={-60}
                          duration={450}
                          onClick={() => {
                            closeNavbar()
                          }}
                        >
                          {item.title}
                        </Link>
                      ))}
                    </div>
                    <div className="bg-gray-50 p-4" hidden>
                      <a
                        href="/"
                        className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <span className="flex items-center">
                          <span className="text-sm font-medium text-gray-900">App</span>
                        </span>
                        <span className="block text-sm text-gray-500">Start integrating products and tools</span>
                      </a>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
          <ul className="mt-4 flex flex-col md:mt-0 md:flex-row  md:text-sm md:font-medium">
            {pages.map((item, i) => (
              <li key={item.title}>
                <Link
                  className="navbar-link block p-5  font-bold md:bg-transparent"
                  activeClass="active"
                  href={`#${pages[i].id}`}
                  to={pages[i].id}
                  smooth="easeOutQuart"
                  spy
                  offset={-60}
                  duration={450}
                >
                  {`${item.title}`}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  )
}
