import Section from '@/components/Section'
import TeamOne from '@/assets/team-01.jpg'
import TeamTwo from '@/assets/team-02.jpg'
import TeamThree from '@/assets/team-03.jpg'
import Testimonials from '@/sections/Testimonials'

export default function About() {
  return (
    <div id="about">
      <Section>
        <div data-aos="fade-up">
          <h2>About</h2>
          <p>
            Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum.
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Lorem ipsum
            .
          </p>
        </div>
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
          <figure className="flex flex-col overflow-hidden rounded-xl border p-0 md:p-6" data-aos="fade-up">
            <img
              className="w-full md:mx-auto md:h-32 md:w-auto md:rounded-full"
              src={TeamOne}
              alt=""
              width="384"
              height="512"
            />
            <div className="space-y-4 p-6  text-center">
              <blockquote>
                <p className="px-10 md:px-0 ">
                  &quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua.&quot;
                </p>
              </blockquote>
              <figcaption className="font-medium">
                <div className="text-sky-500 ">Jeremy Ashley</div>
                <div className="text-slate-700">CEO</div>
              </figcaption>
            </div>
          </figure>
          <figure
            className="flex flex-col overflow-hidden rounded-xl border p-0 md:p-6"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <img
              className="w-full md:mx-auto md:h-32 md:w-auto md:rounded-full"
              src={TeamTwo}
              alt=""
              width="384"
              height="512"
            />
            <div className="space-y-4 p-6 text-center  md:p-6">
              <blockquote>
                <p className="px-10 md:px-0 ">
                  &quot;I hear and I forget.
                  <br />I see and I remember.
                  <br /> I do and I understand.&quot;
                  <br /> ~ Confucius
                </p>
              </blockquote>
              <figcaption className="font-medium">
                <div className="text-sky-500 dark:text-sky-400">Joaquin Moquette</div>
                <div className="text-slate-700 dark:text-slate-500">Developer</div>
              </figcaption>
            </div>
          </figure>
          <figure
            className="flex flex-col overflow-hidden rounded-xl border p-0 md:p-6"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <img
              className="w-full md:mx-auto md:h-32 md:w-auto md:rounded-full"
              src={TeamThree}
              alt=""
              width="384"
              height="512"
            />
            <div className="space-y-4 p-6 text-center  md:p-6">
              <blockquote>
                <p className="px-10 md:px-0 ">
                  &quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua.&quot;
                </p>
              </blockquote>
              <figcaption className="font-medium">
                <div className="text-sky-500 dark:text-sky-400">Jane Doe</div>
                <div className="text-slate-700 dark:text-slate-500">Management</div>
              </figcaption>
            </div>
          </figure>
        </div>
      </Section>
      <Testimonials />
    </div>
  )
}
