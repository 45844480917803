import Section from '@/components/Section'
import React, { useEffect } from 'react'
import Glide from '@glidejs/glide'

import TestimonialsOne from '@/assets/testimonials-01.jpg'
import TestimonialsTwo from '@/assets/testimonials-02.jpg'
import TestimonialsThree from '@/assets/testimonials-03.jpg'

export default function Testimonials() {
  useEffect(() => {
    new Glide(`.glide`, {
      type: `carousel`,
      autoplay: 10000,
      breakpoints: {
        768: {
          perView: 1,
          peek: 10,
        },
        992: {
          perView: 1,
          peek: 100,
        },
      },
    }).mount()
  })
  return (
    <Section className="testimonials py-18 bg-gray-100">
      <div data-aos="zoom-in">
        <div className="row g-3 glide pt-3">
          <div className="glide__track" data-glide-el="track">
            <div className="glide__slides shadow-xl">
              <div className="glide__slide flex rounded-lg bg-white p-5 opacity-80">
                <div className="testimonial-image">
                  <img className="w-32 rounded-full" src={TestimonialsOne} alt="" />
                </div>
                <div className="testimonial-content px-5">
                  <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam</q>
                  <p className="font-semibold text-sky-500">~ John Doe</p>
                </div>
              </div>
              <div className="glide__slide flex rounded-lg bg-white p-5 opacity-80">
                <div className="testimonial-image">
                  <img className="w-32 rounded-full" src={TestimonialsTwo} alt="" />
                </div>
                <div className="testimonial-content px-5">
                  <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam</q>
                  <p className="font-semibold text-sky-500">~ Larry Doe</p>
                </div>
              </div>
              <div className="glide__slide flex rounded-lg bg-white p-5 opacity-80">
                <div className="testimonial-image">
                  <img className="w-32 rounded-full" src={TestimonialsThree} alt="" />
                </div>
                <div className="testimonial-content px-5">
                  <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam</q>
                  <p className="font-semibold text-sky-500">~ Jane Doe</p>
                </div>
              </div>
            </div>
          </div>
          <div className="glide__bullets relative mt-12" data-glide-el="controls[nav]" hidden>
            <button type="button" className="glide__bullet" data-glide-dir="=0" aria-label="bullet" />
            <button type="button" className="glide__bullet" data-glide-dir="=1" aria-label="bullet" />
            <button type="button" className="glide__bullet" data-glide-dir="=2" aria-label="bullet" />
          </div>
        </div>
      </div>
    </Section>
  )
}
