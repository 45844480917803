import React from 'react'

export default function Footer() {
  return (
    <div className="py-10 text-center text-xs  text-gray-500">
      Copyright &copy; {new Date().getFullYear()}
      {` `}
      <span className="font-bold">
        <a href="https://dovevergence.com" target="blank" rel="noopener noreferrer">
          <span className="font-bold">Dovergence LLC</span>
        </a>
        .
      </span>
      <br />
      All rights reserved. Powered by{` `}
      <span className="font-bold">
        <a href="https://dovebox.org" target="blank" rel="noopener noreferrer">
          Dovebox
        </a>
      </span>
    </div>
  )
}
