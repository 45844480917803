import Section from '@/components/Section'

export default function Contact() {
  return (
    <Section id="contact" className="h-screen">
      <div data-aos="fade-up">
        <h2>Contact</h2>
      </div>
    </Section>
  )
}
