import { useEffect } from 'react'
import Nav from '@/components/Nav'
import Hero from '@/sections/Hero'
import Services from '@/sections/Services'
import About from '@/sections/About'
import Contact from '@/sections/Contact'
import Footer from '@/components/Footer'

import AOS from 'aos'

export function Main() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    })
    AOS.refresh()
  }, [])
  return (
    <>
      <Nav />
      <Hero />
      <Services />
      <About />
      <Contact />
      <Footer />
    </>
  )
}
