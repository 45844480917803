import Section from '@/components/Section'

export default function Cta() {
  return (
    <Section id="home" className="cta flex flex-col justify-center text-center">
      <div data-aos="zoom-in">
        <h3>Free Consultation</h3>
        <button className=" mt-3 rounded-lg  bg-black py-2 px-4 hover:bg-gray-900" type="button">
          Contact Us Today
        </button>
      </div>
    </Section>
  )
}
