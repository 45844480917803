import Section from '@/components/Section'

export default function Hero() {
  return (
    <Section id="home" className="hero flex flex-col justify-center">
      <div className="w-[90%] md:w-[85%]" data-aos="fade-up">
        <h1>
          Digital Design
          <br /> &amp; Solutions
        </h1>
        <p>For a real world.</p>
      </div>
    </Section>
  )
}
